import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '@components'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import BlockWrapper from '../pageBuilder/wrapper'

const IndexPage = ({ data }) => {
  const { moduleArea, seoMetaTags } = data.home
  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <BlockWrapper blocks={moduleArea} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    home: datoCmsHomePage {
      id
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      moduleArea {
        ... on DatoCmsHero {
          ...Hero
        }
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsVaccineProgress {
          ...VaccineProgress
        }
        ... on DatoCmsSubheading {
          ...Subheading
        }
        ... on DatoCmsImage {
          ...Image
        }
      }
    }
  }
`
