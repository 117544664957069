import React from 'react'
import { Block, Image, Container } from '@components'

const DatoCmsImage = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <Container maxWidth={props?.portrait ? 'xsmall' : 'small'}>
        <Image elevated {...props} />
      </Container>
    </Block>
  )
}

export default DatoCmsImage
