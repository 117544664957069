import React from 'react'
import * as Sections from './index'

const BlockWrapper = ({ blocks }) => {
  return (
    <>
      {blocks.map((block, index) => {
        const Component = Sections[block.__typename]
        if (!Component) return null
        return (
          <Component
            key={block.__typename + block.id}
            index={index}
            {...block}
          />
        )
      })}
    </>
  )
}

export default BlockWrapper
