import React from 'react'
import { Block, Hero, Container } from '@components'

const DatoCmsHero = ({
  paddingTop = 'single',
  paddingBottom,
  background,
  backgroundStyle,
  heroImage
}) => {
  return (
    <Block
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <Container maxWidth={'medium'}>
        <Hero image={heroImage} />
      </Container>
    </Block>
  )
}

export default DatoCmsHero
