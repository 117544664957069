import React from 'react'
import { Block, VaccineProgress, Container } from '@components'

const DatoCmsVaccineProgress = ({
  paddingTop,
  paddingBottom,
  currentPercentage
}) => {
  return (
    <Block paddingTop={paddingTop} paddingBottom={paddingBottom} gutters>
      <Container maxWidth={'xsmall'}>
        <VaccineProgress currentPercentage={currentPercentage} />
      </Container>
    </Block>
  )
}

export default DatoCmsVaccineProgress
