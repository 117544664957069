import React from 'react'
import { Block, Button, Container } from '@components'

const DatoCmsButton = ({ paddingTop, paddingBottom, background, ...props }) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
    >
      <Container maxWidth={'small'}>
        <Button {...props} to={props?.link?.page?.path || props?.link?.url}>
          {props.link.displayText}
        </Button>
      </Container>
    </Block>
  )
}

export default DatoCmsButton
