import React from 'react'
import { Block, Subheading, Container } from '@components'

const DatoCmsSubheading = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <Container maxWidth={'small'}>
        <Subheading {...props} />
      </Container>
    </Block>
  )
}

export default DatoCmsSubheading
